.layout-airtable .intro-container {
    height: 190px;
    .lead {
        text-align: center; } }
.at-view {
    z-index: 100;
    .sticky {
        top: 0; }
    min-height: 540px;
    .multi-select {
        margin-top: 2px;
        // margin-right: 10px
        min-width: 150px;
        max-width: 200px;
        width: 100%; }
    .card {
        margin-bottom: 15px; }
    .flex-container {
        &.title {
            align-items: center;
            h4 {
                margin: 0; } }
        & > div {
            flex: 1; }
        .details {
            flex: 2; } }
    ul.tags {
        margin-bottom: 0 !important;
        li {
            display: block; } }
    .view-filter {
        margin: 10px 0;
        .ui.button.small {
            position: relative;
            i.close {
                position: absolute;
                color: inherit;
                right: -2px;
                top: 10px; } } }
    .table-filters {
        // background-color: lightgrey
        padding: 8px; }

    .features {
        padding: 0; }
    .card-image {
        overflow-y: hidden;
        // max-width: 280px
        padding: 0 35px;
        & > img {
            margin: 0; } }

    .advertiser-brand {
        padding: 13px 0;
        margin: 0; }
    // .gallery-view
    //     .comparison
    //         & > div
    //             padding: 0 3px
    .gallery-view {
        .title {
            background-color: lightgrey; }
        .label {
            text-decoration: underline; } }
    .record .column {
        padding: 0; } }
.toggle-layout {
    max-width: 98px;
    button {
        margin: 0 1px; } }


.comparison-attr {
    label {
        font-size: 0.9rem;
        text-decoration: underline; } }

@media(max-width:899px) {
    .layout-airtable .intro-container {
        height: 152px;
        margin-top: 25px;
        margin-bottom: 12px; } }

