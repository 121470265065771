
$white: rgba(254,255,250,1);
$grey: #eeeded;
$red: rgba(255,104,115,1);
$black: rgba(48,69,92,0.8);

$sans: 'Titillium Web', sans-serif;

.transition {
  transition: all 0.25s ease-in-out; }

.flipIn {
  animation: flipdown 0.5s ease both; }

// .article-post
//   p
//     color: $black
//     font-size: 17px
//     line-height: 26px
//     letter-spacing: 1px
//     position: relative
//     overflow: hidden
//     max-height: 800px
//     @extend .transition
//     opacity: 1
//     transform: translate( 0 , 0 )
//     margin-top: 14px
//     z-index: 2

ul.accordion {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
  z-index: 3;
  padding-left: 0 !important;
  & > li {
    position: relative;
    padding: 0;
    margin: 0;
    .title {
      padding-left: 1rem;
      padding-bottom: 1px;
      padding-top: 5px;
      padding-right: 25px; }
    p {
      padding-left: 1rem;
      padding-bottom: 4px;
      padding-top: 7px; }
    @extend .flipIn;
    &:nth-of-type(1) {}
    animation-delay: 0.5s;

    &:nth-of-type(2) {}
    animation-delay: 0.75s;

    &:nth-of-type(3) {}
    animation-delay: 1.0s;

    &:last-of-type {}
    padding-bottom: 0;

    p {
      margin-bottom: 0; }

    & > i {
      position: absolute;
      transform: translate( -6px , 0 );
      margin-top: 8px;
      right: 10px;

      &:before , &:after {
        content: "";
        @extend .transition;
        position: absolute;
        background-color: $secondary;
        width: 3px;
        height: 9px; }

      &:before {
        transform: translate( -2px , 0 ) rotate( 45deg ); }

      &:after {
        transform: translate( 2px , 0 ) rotate( -45deg ); } }

    .extra-content {
      margin-top: auto;
      max-height: 0;
      opacity: 0;
 }      // transform: translate( 0 , 50% )
    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;

      &:checked {
        &~p,&~ul, &~[itemprop="acceptedAnswer"] {
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          transform: translate( 0 , 50% );
          padding-bottom: 0px;
          padding-top: 0px; }

        &~i {
          &:before {
            transform: translate( 2px , 0 ) rotate( 45deg ); }

          &:after {
            transform: translate( -2px , 0 ) rotate( -45deg ); } } } } } }

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg); }
  5% {
    opacity: 1; }
  80% {
    transform: rotateX(8deg); }
  83% {
    transform: rotateX(6deg); }
  92% {
    transform: rotateX(-3deg); }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg); } }
// .product-card
//   ul.accordion:
//  // text-de

.faq.accordion {
  border: 1px solid lightgrey;
  li {
    border-bottom: 1px solid grey;
    padding-top: 3px;
    padding-bottom: 3px;
    &:hover {
      .title {
        background-color: lightgrey; } } }
  .title {
    background-color: $grey; } }

