.nag {
    border: 1px solid;
    border-color: #d0d0d0;
    margin-top: 10px !important;
    bottom: 0;
}
.nag.show{
    max-height: 80px;
}
.nag > .flex-container > div {
    padding: 5px;
}
