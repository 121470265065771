.sidebar {
  min-width: 318px;
  .ui.segment a {
      color: darkslategrey;
      text-decoration: underline; }
  .mini-chart {
    ul:first-child {
      border-bottom: none; }

    .chart-title {
      background: orange;
      /* position: absolute */
      z-index: 100;
      margin: 0 auto;
      margin-top: -62px; }


    ul:last-child {
      border-bottom: none; }
    .logo {
      width: 156px;
      img {
        max-height: 40px; } }
    .ui.segment a {
      text-decoration: none; } } }
