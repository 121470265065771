.footer {
	border-top:1px solid rgba(0,0,0,.05)!important;
	color: white;
	/* margin-top:50px; */
	margin-bottom:0px;
	background-color: #3d80cc;
    padding: 30px 0;
}
.footersocial i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
    font-size: 18px;
    margin-left: 5px;
    color: #3c3838;
    margin-top: 10px;
}
.beforefooter {
    /* background: #fff100;
    color: #111; */

    /* background-color: #a54bf1; */
    /* background-image: linear-gradient(#313fca, #a54bf1); */
    // color:white;
    padding: 40px 0;
    margin-top: 50px;
    max-width: 100%;
    /* margin: 0px auto; */
    /* margin-bottom: -50px; margin-top:50px; */
}
.footer a {color:inherit;}
.footer a:hover {color:#fff; text-decoration:none;}
.footer-widget {
    /* margin-bottom: 3rem;    
    margin-top: 3rem; */
    font-size:15px;
}
.footer-widget h5.title {font-size:18px; text-transform:uppercase;letter-spacing:1px;color:#fff;margin-bottom:1.5rem;}
.footer-widget ul {list-style:none;padding-left:0;}
.footer-widget ul li {margin:12px 0;}
.footer-widget .recent-posts img {float:left;margin-right:10px;}
.footer .textwidget a {    border-bottom: 1px dashed;}
.beforefooter h3 {margin-bottom:1rem;}

ul.footer-links{
    padding-inline-start: 10px;
}
ol.breadcrumb{
    margin-bottom: 0;
}
.footer-links li {
    list-style: none;
    font-size: 1.1rem;
}
.footer-links li a:hover{
    text-decoration: underline;
}