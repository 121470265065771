// Import partials from `sass_dir` (defaults to `_sass`)

// $purple: #866bf4;
$primary: #3d80cc;
$secondary: #283147;

$purple: #3d80cc;
$blue: #082E6F;
$flag: #3ECC8D;
@import "comparo/_syntax";
@import "comparo/_glyphs";
@import "comparo/_accordion";
@import "comparo/_search";
@import "comparo/_member";
@import "comparo/_sidebar";
@import "comparo/_nag";
@import "comparo/_popup";
@import "comparo/_footer";


.pre-content{
	margin-top: 15px;
}

.card-block{
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.25rem;
}
.card{
	box-shadow: 0 0px 9px 0 rgba(0, 0, 0, 0.14);
}

.navbar-abs{
	position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50px;
		&, a{
		color: white;
	}
		z-index: 300;
}
.navbar-dark .navbar-nav .nav-link{
	color: white;
}
.crop {
	overflow: hidden;
	width:100%;
	max-height: 300px;
}

fieldset, label { margin: 0; padding: 0; }
// body{ margin: 20px; }
// h1 { font-size: 1.5em; margin: 10px; }
h1 { font-size: 2.5em }

.beforefooter{
	padding: 40px 0;
}
.postbox{
	margin-bottom: 10px;
	.card-img-container{
		max-height: 220px;
		overflow: hidden;
	}
	.card {
		margin-bottom: 16px;
	}
	.card-title{
		color: black;
		a {
			color: inherit;
		}
		font-size: 1.2rem;
		font-weight: 700;
		// line-height: 1.25;
	}
	.card-text{
		position: relative;
		color: #666;
		// font-size: 0.8rem;
		line-height: 1.2;
		font-weight: 400;
		margin-bottom: 0;
		overflow: hidden;
		// max-height: 70px;
	}
	.flex-item:nth-of-type(1) { flex-grow: 1; }
	.flex-item:nth-of-type(2) { flex-grow: 5; }
	.flex-item:nth-of-type(2){ padding: 1rem;}
	h2.card-title{
		// font-size: 1rem;
		margin-bottom: 0;
	}
	.flex-container{
		flex-flow: row;
	}
	img{
	}
	.post-thumbnail{
		// height: 150px;
		height: 100%;
		width: 120px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	.post-read-more {
		text-align: center;
		margin: 10px;
	}
	// .elipsis{
	// 	bottom: 39px;
	// }
}

.product-cta-container{
    // position: absolute;
    // right: 16px;
	// bottom: 10px;
	p {
		margin-bottom: 5px;
	}
	.cta-text{
		font-size: 0.9rem;
	}
}
.article-post{
	
	ul{
		padding-left: 1rem;
	}
}

.navbar-container{
	z-index: 300;
}
.main-content{
	z-index: 100;
}
// Reviews
.reviews{
	.reviewbox{
		.post-thumbnail{
			background-size: contain;
			width: 100%;
			height: 32px;
			margin: 12.5px 15px 6px;
			background-position: left;

		}
		@extend .postbox;
	}
}

.beforefooter{
	background: $secondary;
}

.sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
	// top: 56px;
	top: 0px;
	z-index: 10;
}
.elipsis{
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#fff));
	position: absolute;
    width: 100%;
    bottom: 0px;
    height: 50px;
    left: right;
    z-index: 2;
}

.sticky {
	.aff-link > .card-img-top {
		margin-bottom: 0;
	}
}
#navbarSupportedContent{
	justify-content: flex-end;
}


@media (max-width: 800px) {
	.intro{
		width: 100%;
		// background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url("../images/stock-header-img-optimized.jpg");
		background-size: cover;
		background-position: center;
		// .lead > * {
		// 	margin-bottom: 0;
		// }
	}
	.reviews{
		div[class^="col-md-"]{
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.btn-primary{
	background-color: orange;
	border: 2px solid darkorange;
	&:hover{
		background-color: darkorange;
		opacity: 0.85;
		border: 2px solid orange;
		// color: darkorange;
	}
}

.final-cta-title{
	width: 100%;
    text-align: center;
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: #fff;
    margin: 10px 0 50px;
}

.sidebar{
	ul {
		margin: 0;
	}
}

.copy-to-clipboard{
	cursor: pointer;
}

.hidden {
	width: 0;
	display: none;
	opacity: 0;
}

@keyframes slide {
  0%,100% { width: 0; }
  50% { width: 138px; }
}

#dont-leave > .modal-dialog {
	.modal-content{
		border: 2px solid grey;
	}
	max-width: 600px;
	#close-popup{
		color: black;
		position: absolute;
		top: 2px;
		right: 5px;
		font-size: 22px;
		z-index: 1;
		padding: 0 5px;
		cursor: pointer;
	}
}
.affiliate-banner{
	background-color: orange;
	box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
	img {
		opacity: 0.95;
	}
}
.deep-link-list{
	.flex-container{
		flex-flow: row nowrap;
		justify-content: space-between;
	}
	.btn-primary{
		min-width: 100px;
	}
	a.aff-link{
		&{
			text-decoration: none;
		}
		// &:not(button)
		// 	text-decoration: underline;
		// }
		&:hover{
			color: orange;
		}
		.btn-primary{
			padding: 2px;
		}
	}
}


.btn-secondary{
	background-color: transparent;
	border: none;
	color: blue;
}

// #toc{
// 	// position: fixed;
// 	max-width: 400px;
// 	li{
// 		margin: 10px 0;
// 	}
// }

#black-friday-banner{
	display: none;
	position: sticky;
	bottom: 0;
	padding: 7.5px;
	background-color: $blue;
	color: white;
	i.fa-times{
		position: absolute;
		// top: 0;
		right: 5px;
		font-size: 1.4rem;
		color: lightgrey;
		font-weight: 100;
	}
}

.content-button-one {
	box-shadow:inset 0px 1px 0px 0px #29bbff;
	background:linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
	background-color:#2dabf9;
	border-radius:6px;
	border:1px solid #ffffff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Trebuchet MS;
	font-size:16px;
	font-weight:bold;
	padding:15px 15px;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}
.content-button-one:hover {
	background:linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
	background-color:#0688fa;
}
.flex-center {
	display: flex;
	align-items: center;
}


// NEW CSS BELOW THIS LINE


.beforefooter{
	background: linear-gradient(to right, #3D80CB 0%, #27D1C9 100%);
}

.brand-banner.sticky{
	top: 0;
	z-index: 11;
	background-color: white;
}
.brand{
	font-size: 19px;
	color: white;
	font-weight: 400;
	padding: 10px 4px;
}
.multi-select *{
	z-index: 200;
}
.text-center{
	text-align: center;
}
// Reviews
.brand-logo{
	max-width: 139px;
	background: white;
}

.cookie-consent {
	border: 1px solid grey
}

// Blocks

.text-snippet-block, .content-block{
	font-size:1.1rem;
	line-height:1.8;
	color:rgba(0,0,0,.8);
	ul{
		padding-left: 1rem;
	}
}
.table-of-contents-block{
	h3{
		margin-bottom: 0.4rem;
	}
	margin-bottom: 0.4rem;
}